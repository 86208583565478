import React from "react";
import { Link } from "react-router-dom";

class Logo extends React.Component {
  render() {
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        className="col-md-1 col-5"
      >
        <Link to="/">
          <img
            className=" pl-3 pt-2 pb-1"
            id="logo"
            src="/images/logo1.png"
            alt=""
          />
        </Link>
      </div>
    );
  }
}

export default Logo;
