import React, { useContext } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import { AppContext } from "../App";
import Item from "../components/Item";

const PVCkese = () => {
  const { proizvodi } = useContext(AppContext);

  return (
    <div className="wrapper">
      <Header page="KESE TREGERICE" />
      <div className="container">
        <h1 className="text-center">PVC kese</h1>
        <div className="grid-container">
          {proizvodi
            .filter((p) => p.kategorija === "pvc kese")
            .map((p) => (
              <Item proizvod={p} />
            ))}
        </div>
        <div className="mb-5 text-right">
          <Link to="/kontakt" className=" link-btn">
            Kontaktirajte nas <i className="fa fa-arrow-right"></i>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PVCkese;
