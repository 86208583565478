import { Link } from "react-router-dom";

const Item = ({ proizvod }) => {
  return (
    <div key={proizvod.id}>
      <Link
        className="link"
        to={`/specifikacijaproizvoda/${proizvod.tag}/${proizvod.id}`}
      >
        <div className="grid-item">
          <div className="card cards">
            <div className="card-body">
              <h5 className="card-title text-center">{proizvod.naziv}</h5>
              <p className="text-center card-text">{proizvod.dimenzija}</p>
              <p className="text-center card-text">{proizvod.items[0]?.boja}</p>
              <p className="text-center card-title">
                Cena{" "}
                {proizvod.items[0]?.cena
                  ? `${proizvod.items[0]?.cena} din`
                  : "Na upit"}
              </p>
            </div>
            <div className="m-1">
              <img
                style={{ maxWidth: 272, maxHeight: 272 }}
                src={proizvod.items[0]?.img}
                className="card-img-top"
                alt="..."
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default Item;
