import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { createContact } from "../actions";

const ContactForm = () => {
  const [contactValues, setContactValues] = useState({
    name: "",
    email: "",
    subject: "",
    description: "",
    phone: "",
  });

  const onSubmit = async () => {
    await createContact(contactValues);
  };

  const setValue = (value, key) => {
    setContactValues({
      ...contactValues,
      [key]: value,
    });
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <TextField
        onChange={(data) => setValue(data.target.value, "name")}
        label="Vaše Ime i Prezime:"
        fullWidth
        value={contactValues.name}
        variant="outlined"
      />
      <TextField
        value={contactValues.email}
        variant="outlined"
        fullWidth
        label="Email:"
        onChange={(data) => setValue(data.target.value, "email")}
      />
      <TextField
        value={contactValues.phone}
        variant="outlined"
        fullWidth
        label="Telefon:"
        onChange={(data) => setValue(data.target.value, "phone")}
      />
      <TextField
        value={contactValues.subject}
        variant="outlined"
        fullWidth
        label="Tema:"
        onChange={(data) => setValue(data.target.value, "subject")}
      />
      <TextField
        value={contactValues.description}
        variant="outlined"
        label="Poruka:"
        fullWidth
        onChange={(data) => setValue(data.target.value, "description")}
      />
      <button onClick={onSubmit} className="btn submit-button mb-2">
        Pošalji poruku
      </button>
    </Box>
  );
};

export default ContactForm;
