import React from "react";
import Footer from "./Footer";
import Header from "../components/Header";

const Onama = () => {
  return (
    <div className="wrapper">
      <Header page="O NAMA" />
      <div className="onama-container">
        <h1 className="text-center">Papirne kese sa štampom</h1>
        <div className="row">
          <div className="col-md-6 col-12 text-center">
            <img src="./images/kesa3.jpg" alt="" />
          </div>
          <div className="col-md-6 col-12">
            <p>
              Nudimo fleksibilnu štampu, maksimalno do dve boje na belim i
              smeđim papirnim kesama svih veličina, minimalan broj kesa je od
              1000 komada. Pošaljite nam svoj logotip u vektorskom formatu na
              ppa.ecoplastex@gmail.com kako bismo mogli da napravimo formu za
              štampanje, što je za vas samo jednokratni trošak od 20 do 40 evra
              (u zavisnosti od veličine logotipa). Za izradu kesa potrebno je
              oko 7 do 10 radnih dana.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="onama-container">
        <h1 className="text-center">Papirne kese</h1>
        <div className="row">
          <div className="col-md-6 col-12">
            <p>
              U ponudi papirne kese različitih dimenzija. Papirne kese su
              zauzele veliku primenu poslednjih godina zbog svoje razgradivosti
              i očuvanja ekologije u svetu. Takodje mogućnost stampe na papirnim
              kesama daje veliku prednost u odnosu na pvc kese zbog reklamiranja
              i unikatnosti ambalaže. Pored toga zbog čvrste ručke (ravne ili
              upredene) nošenje je dosta olakšano, a sam kvalitet izrade kesa
              omogućava veliki kapacitet svake kese.
            </p>
          </div>
          <div className="col-md-6 col-12 text-center">
            <img src="./images/kese3.jpg" alt="" />
          </div>
        </div>
      </div>
      <hr />
      <div className="onama-container">
        <h1 className="text-center">Proizvodnja plastične ambalaže</h1>

        <div className="row">
          <div className="col-md-6 col-12 text-center">
            <img src="./images/pvckese4.jpg" alt="" />
          </div>
          <div className="col-md-6 col-12">
            <p>
              Prva delatnost Eco Plastexa je proizvodnja plastične ambalaže,
              kesa i vreća. Iako papirne kese zauzimaju sve veći stepen
              upotrebe, plastična ambalaža još uvek ima široku upotrebu. Naš
              asortiman plastične ambalaže možete pogledati u sekciji PVC.
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Onama;
