import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Drawer,
  Typography,
  IconButton,
  TextField,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { AppContext } from "../App";

const Chart = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { inChart, setInChart, handleOrder } = useContext(AppContext);

  const [contactValues, setContactValues] = useState({
    name: "",
    email: "",
    phone: "",
  });

  const setValue = (value, key) => {
    setContactValues({
      ...contactValues,
      [key]: value,
    });
  };

  const getNeededItem = (item) => {
    if (item.boja) {
      return item.proizvod.items.find((t) => t.boja === item.boja);
    }
  };

  // Function to handle quantity change
  const handleQuantityChange = (index, value) => {
    const newInChart = [...inChart];
    newInChart[index].kolicina = Math.max(0, value); // Prevent negative quantities
    setInChart(newInChart);
  };

  // Function to remove an item from the chart
  const removeItem = (index) => {
    const newInChart = inChart.filter((_, i) => i !== index);
    setInChart(newInChart);
  };

  // Calculate total for each item
  const calculateTotal = (item) => {
    const cena = getPrice(item);
    if (cena === 0) {
      return 0;
    }
    return cena * item.kolicina;
  };

  const getPrice = (item) => {
    const cena = getNeededItem(item)?.cena ?? 0;

    return cena;
  };

  // Calculate grand total for all items
  const calculateGrandTotal = () => {
    let imaNaUpit = false;
    let total = 0;
    for (const item of inChart) {
      const cena = getPrice(item);
      if (cena === 0) {
        imaNaUpit = true;
      } else {
        const price = calculateTotal(item);
        total += price;
      }
    }

    return `Ukupno za sve proizvode: ${total} RSD + (proizvodi na upit)`;
  };

  const handleOrderButtonPress = () => {
    handleOrder(inChart, contactValues);
    setContactValues({
      name: "",
      email: "",
      phone: "",
    });
  };

  return (
    <>
      <Button
        color="success"
        sx={{
          borderRadius: "50%",
          boxShadow: "0px 0px 20px 1px grey",
          height: "60px",
          width: "60px",
          minWidth: "60px",
          position: "fixed",
          zIndex: 100,
          bottom: 30,
          right: 30,
          "&:focus": {
            outline: "none",
          },
        }}
        variant="contained"
        onClick={() => setIsOpen(!isOpen)}
      >
        <ShoppingCartIcon />
        {inChart.length > 0 && (
          <Box
            sx={{
              position: "absolute",
              textAlign: "center",
              bottom: 0,
              right: 0,
              width: 25,
              height: 25,
              borderRadius: 25,
              backgroundColor: "#d60024",
              color: "white",
              fontSize: "12px",
            }}
          >
            {inChart.length}
          </Box>
        )}
      </Button>
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            height: "100%",
            top: "auto",
            width: "500px",
            backgroundColor: "#f5f5f5",
            position: "relative", // Added to position close button
          },
        }}
        ModalProps={{
          keepMounted: true,
          BackdropProps: {
            style: {
              backgroundColor: "#00000040",
            },
          },
        }}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <IconButton
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            color: "#d60024", // Close button color
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ padding: "20px" }}>
          <Typography variant="h4" gutterBottom>
            Korpa
          </Typography>

          {inChart.length > 0 ? (
            <>
              {inChart.map((item, index) => (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  key={index}
                  sx={{
                    marginBottom: 2,
                    borderBottom: "1px solid #e0e0e0",
                    paddingBottom: 1,
                    paddingRight: "10px",
                  }}
                >
                  <img
                    src={getNeededItem(item)?.img}
                    alt={item.proizvod.naziv}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      marginRight: "10px",
                      boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
                    }}
                  />
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant="body1">
                      {item.proizvod.naziv}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "grey.600",
                        display: "inline",
                        marginLeft: 1,
                      }}
                    >
                      ({item.proizvod.dimenzija})
                    </Typography>
                    {/* Show price calculation: price * quantity = total */}
                    <Typography variant="body2" sx={{ color: "grey.700" }}>
                      {calculateTotal(item) !== 0
                        ? `${calculateTotal(item)} RDS`
                        : `Na upit`}
                    </Typography>
                  </Box>

                  <TextField
                    type="number"
                    value={item.kolicina}
                    onChange={(e) =>
                      handleQuantityChange(index, parseInt(e.target.value))
                    }
                    inputProps={{ min: 0, style: { textAlign: "center" } }}
                    sx={{
                      width: "90px",
                      marginX: "10px",
                      "& input": { padding: "5px" },
                    }}
                  />

                  <IconButton onClick={() => removeItem(index)}>
                    <DeleteIcon sx={{ color: "#d60024" }} />
                  </IconButton>
                </Box>
              ))}

              {/* Grand Total */}
              <Typography variant="h6" align="center" sx={{ marginTop: 2 }}>
                {calculateGrandTotal()}
              </Typography>

              <Typography
                fontSize={10}
                variant="body2"
                align="center"
                sx={{ margin: 0, marginTop: 2 }}
              >
                * Cene proizvoda "Na upit" će biti dogovorene prilikom potvrde
                narudžbine od strane naših zaposlenih
              </Typography>

              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={1}
                sx={{ marginTop: "30px", marginBottom: "30px" }}
              >
                <TextField
                  onChange={(data) => setValue(data.target.value, "name")}
                  label="Ime i Prezime*"
                  fullWidth
                  value={contactValues.name}
                  variant="outlined"
                />
                <TextField
                  value={contactValues.email}
                  variant="outlined"
                  fullWidth
                  label="Email*"
                  onChange={(data) => setValue(data.target.value, "email")}
                />
                <TextField
                  value={contactValues.phone}
                  variant="outlined"
                  fullWidth
                  label="Telefon*"
                  onChange={(data) => setValue(data.target.value, "phone")}
                />
              </Box>

              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={() => handleOrderButtonPress()}
                disabled={
                  !contactValues.email ||
                  !contactValues.name ||
                  !contactValues.phone
                }
                sx={{ marginTop: 2 }}
              >
                Pošalji zahtev za nadudžbinu
              </Button>
            </>
          ) : (
            <Box sx={{ textAlign: "center", marginTop: 2 }}>
              <Typography variant="body1" sx={{ color: "grey.500" }}>
                Korpa je prazna
              </Typography>
              <Typography variant="body2" sx={{ color: "grey.400" }}>
                Dodajte proizvode u svoju korpu
              </Typography>
            </Box>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default Chart;
