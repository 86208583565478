import React, { useContext, useEffect, useState } from "react";
import Footer from "./Footer";
import { Link, useParams } from "react-router-dom";
import Header from "../components/Header";
import { AppContext } from "../App";
import Item from "../components/Item";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

const SpecifikacijaProizvoda = () => {
  const { tag: _tag, id } = useParams();
  const { proizvodi, addToChart } = useContext(AppContext);
  const [proizvod, setProizvod] = useState(
    proizvodi.filter((p) => p.id === id)[0]
  );

  const [color, setColor] = useState(null);
  const [selectedQuantity, setSelectedQuantity] = useState(
    proizvod?.minimum ?? 1
  );
  useEffect(() => {
    if (proizvod) {
      setColor(proizvod.items[0].boja);
      setSelectedQuantity(proizvod.items[0].minimum ?? 1);
      window.scrollTo(0, 0);
    }
  }, [proizvod]);

  const getNeededItem = () => {
    if (color) {
      return proizvod.items.find((t) => t.boja === color);
    }
  };

  const renderPrice = () => {
    const rawCena = proizvod.items.find((i) => i.boja === color)?.cena;
    return typeof rawCena === "number"
      ? `Cena ${rawCena} din/kom sa PDV-om`
      : " Cena NA UPIT";
  };

  useEffect(() => {
    setProizvod(proizvodi.filter((p) => p.id === id)[0]);
  }, [proizvodi, id]);
  return (
    <div className="wrapper">
      <Header />
      <div>
        {proizvodi.length && !!proizvod ? (
          <div className="container mt-5">
            <div className="row">
              <div className="col-lg-5 text-center mt-5">
                <img
                  src={getNeededItem()?.img}
                  style={{ maxWidth: "100%" }}
                  alt=""
                />
              </div>
              <div className="col-lg-7 ">
                <h2 className="text-center">{proizvod.naziv}</h2>
                <hr />
                <p>{proizvod.opis}</p>
                {proizvod.tag.includes("papirna-kesa") && (
                  <Link
                    to={"/papirnekesesastampom"}
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"center"}
                    >
                      <img
                        style={{ width: 50 }}
                        src="../../images/kesalogo6.png"
                        alt=""
                      />
                      <Typography
                        fontSize={22}
                        className="glow-text"
                        marginX={5}
                      >
                        Mogućnost štampe na kesama
                      </Typography>
                      <img
                        style={{ width: 50 }}
                        src="../../images/kesalogo6.png"
                        alt=""
                      />
                    </Box>
                  </Link>
                )}
                <hr />
                <h5>Specifikacija:</h5>
                <p className="mb-0 pb-1">Dimenzija: {proizvod.dimenzija}</p>
                <p className="mb-0 pb-1">
                  Materijal: {getNeededItem()?.materijal}
                </p>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-start"}
                >
                  <p className="mb-0 pb-1">Boja: </p>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <Select
                      color="success"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={color}
                      onChange={(d) => setColor(d.target.value)}
                    >
                      {proizvod.items.map((item) => (
                        <MenuItem value={item.boja}>{item.boja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <p className="mb-0 pb-1">Tiraž: {getNeededItem()?.tiraz}</p>

                <hr />

                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"start"}
                >
                  <Box>
                    <h5>{renderPrice()}</h5>
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      sx={{ width: 200, marginTop: "20px" }}
                    >
                      <TextField
                        label={`Željena količina ${
                          getNeededItem()?.minimum
                            ? `minimum ${getNeededItem()?.minimum}`
                            : ""
                        }`}
                        type="number"
                        color="success"
                        onBlur={(d) => {
                          setSelectedQuantity(
                            parseInt(d.target.value) <
                              (getNeededItem()?.minimum ?? 0)
                              ? getNeededItem()?.minimum ?? 0
                              : parseInt(d.target.value)
                          );
                        }}
                        onChange={(d) =>
                          setSelectedQuantity(
                            parseInt(d.target.value) <
                              (getNeededItem()?.minimum ?? 0)
                              ? getNeededItem()?.minimum ?? 0
                              : parseInt(d.target.value)
                          )
                        }
                        sx={{
                          width: "100%",
                          mr: 1,
                        }}
                        value={selectedQuantity}
                        InputProps={{ style: { borderRadius: 8 } }}
                      />
                    </Stack>
                  </Box>
                  <Button
                    sx={{
                      minHeight: "60px",
                      marginTop: "20px",
                    }}
                    variant="contained"
                    color="success"
                    onClick={() =>
                      addToChart(proizvod, selectedQuantity, color)
                    }
                  >
                    <Box>
                      <Typography textTransform={"none"} fontSize={16}>
                        Dodaj u korpu
                      </Typography>
                      {getNeededItem()?.cena && (
                        <Typography textTransform={"none"} fontSize={14}>
                          {new Intl.NumberFormat().format(
                            (getNeededItem()?.cena ?? 0) * selectedQuantity
                          )}{" "}
                          din sa PDV-om
                        </Typography>
                      )}
                    </Box>
                    <ShoppingCartIcon sx={{ marginLeft: 2 }} />
                  </Button>
                </Box>
                <hr />
                <div className="mb-5 text-right">
                  <Link to="/kontakt" className=" link-btn">
                    Kontaktirajte nas <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div>
              <h3 className="text-center">Slični proizvodi</h3>
              <div className="grid-container">
                {proizvodi
                  .filter(
                    (p) =>
                      p.kategorija === proizvod.kategorija &&
                      p.id !== proizvod.id
                  )
                  .map((p) => {
                    return <Item proizvod={p} />;
                  })}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center mb-5 mt-5" style={{ height: "250px" }}>
            <h1 className="">Stranica nije pronađena</h1>
            <Link className="link-btn" to="/">
              Nazad na početnu
            </Link>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SpecifikacijaProizvoda;
