import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Header from "../components/Header";

const SaStampom = () => {
  return (
    <div className="wrapper">
      <Header page="PAPIRNE KESE SA ŠTAMPOM" />
      <h1 className="text-center mt-4">Vaša reklama je naša briga</h1>
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-7 text-center ">
            <img src="./images/kesalogo6.png" alt="" />
          </div>
          <div className="col-lg-5  text-left">
            <h2>Papirne kese sa štampom</h2>
            <p className="mt-5">
              Nudimo fleksibilnu štampu, maksimalno do dve boje na belim i
              smeđim papirnim kesama svih veličina, minimalan broj kesa je od
              250 komada. Pošaljite nam svoj logotip u vektorskom formatu na
              ppa.ecoplastex@gmail.com kako bismo mogli da napravimo formu za
              štampanje, što je za vas samo jednokratni trošak od 20 do 40 evra
              (u zavisnosti od veličine logotipa). Za izradu kesa potrebno je
              oko 7 do 10 radnih dana.
            </p>
            <h4 className="mt-5">Specifikacija:</h4>
            <div className="mt-2">
              <p className="mb-0 pb-1">
                Moguće dimenzije: zavisno od izbora papirne kese
              </p>
              <p className="mb-0 pb-1">Materijal: kraft papir</p>
              <p className="mb-0 pb-1">Boja kese: braon, bela</p>
              <p className="mb-0 pb-1">Boja štampe: maximum 2 boje po izboru</p>
            </div>
          </div>
        </div>

        <h3 className="mt-5 text-center">Galerija</h3>
        <div className="row print-gallery">
          <div className="col-md-3 col-6 m-0 p-0">
            <div className="m-3">
              <img
                src="./images/kesa3.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="m-3">
              <img
                src="./images/kesa5.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
          </div>
          <div className="col-md-3 col-6 m-0 p-0">
            <div className="m-3">
              <img
                src="./images/kesa5.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="m-3">
              <img
                src="./images/kesa3.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
          </div>
          <div className="col-md-3 col-6 m-0 p-0">
            <div className="m-3">
              <img
                src="./images/kesa8.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="m-3">
              <img
                src="./images/kesa3.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
          </div>
          <div className="col-md-3 col-6 m-0 p-0">
            <div className="m-3">
              <img
                src="./images/kesa3.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
            <div className="m-3">
              <img
                src="./images/kesa8.jpg"
                className="card-img-top"
                alt="..."
              />
            </div>
          </div>
        </div>
        <div className="mb-5 text-right">
          <Link className=" link-btn" to="/kontakt">
            Kontaktirajte nas <i className="fa fa-arrow-right"></i>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SaStampom;
