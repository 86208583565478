import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";
import Header from "../components/Header";

const Kontakt = () => {
  return (
    <div className="wrapper">
      <Header page="KONTAKT" />
      <div className="container ">
        <div className="row">
          <div className="col-md-7">
            <div className="mt-5 mb-3 ">
              <h1>ECO PLASTEX</h1>
              <h3>
                Štampa na papirnim kesama, papirne kese i proizvodnja plastične
                ambalaže
              </h3>
              <h4>Direktor: Branko Mihailović</h4>
              <h4>Adresa: Straža, 15307 Lešnica</h4>
              <h4>
                <i className="fa fa-phone"></i> +381 63 836 6155
              </h4>
              <h4>email: ppa.ecoplastex@gmail.com</h4>
              <br />
              <h4>Zapratite nas:</h4>
              <Link to="#" className="mr-3 ">
                <i className="fa fa-facebook-square fa-2x"></i>
              </Link>
              <Link to="#">
                <i className="fa fa-instagram fa-2x"></i>
              </Link>
              <div>
                <h4 className="mt-5">Kako do nas?</h4>
              </div>
              <div>
                <iframe
                  title="mapa"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d305783.222590058!2d19.170842401739094!3d44.59837021533418!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475bdea822e494c9%3A0xc1bfe12c0bc1442c!2z0KHRgtGA0LDQttCwLCDQodGA0LHQuNGY0LA!5e0!3m2!1ssr!2sus!4v1572538435831!5m2!1ssr!2sus"
                  width="400"
                  height="400"
                  frameBorder="0"
                  style={{ border: "0" }}
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <h2 className=" mt-5 mb-4">Kontaktirajte nas:</h2>
            <ContactForm />
          </div>
        </div>
        <div className="message">Poruka je poslata</div>
        <div className="name-input">Unos brojeva nije dozvoljen</div>
      </div>
      <footer className="footer mt-5 ">
        <div className="text-center ">
          <p className="pb-2 mb-0">
            &copy; 2019. ECO PLASTEX. Sva prava zadrzana
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Kontakt;
