import React, { useContext } from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Item from "../components/Item";
import { AppContext } from "../App";

const PVCvrece = () => {
  const { proizvodi } = useContext(AppContext);

  return (
    <div className="wrapper">
      <Header page="VREĆE" />
      <div className="container">
        <h1 className="text-center">PVC vreće</h1>
        <div className="grid-container">
          {proizvodi
            .filter((p) => p.kategorija === "pvc vrece")
            .map((p) => {
              return p.items.map((i) => (
                <Item
                  proizvod={{
                    ...p,
                    items: [i],
                  }}
                />
              ));
            })}
        </div>
        <div className="mb-5 text-right">
          <Link to="/kontakt" className=" link-btn">
            Kontaktirajte nas <i className="fa fa-arrow-right"></i>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PVCvrece;
