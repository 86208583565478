import React from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

const RenderCard = ({ linkTo, imgString, text }) => {
  return (
    <div className="col-md-3 col-6 m-0 p-0 ">
      <Link style={{ textDecoration: "none" }} to={linkTo}>
        <div className="card cards">
          <div className="m-1 card-img-top-container">
            <img src={imgString} className="card-img-top" alt="..." />
          </div>
          <div className="card-body">
            <h5 className="card-title text-center">{text}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default RenderCard;
