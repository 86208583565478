import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import Header from "../components/Header";
import RenderCard from "../components/MainPageCard";
import { Box } from "@mui/material";
import { Image } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from "jquery"; // Import jQuery
import "slick-carousel"; // Import Slick Carousel

const imageSources = [
  "./images/kesaravnaiznutra2.jpg",
  "./images/browWhite.jpeg",
  "./images/group1.jpg",
  "./images/bag1.jpeg",
  "./images/whiteBag.jpeg",
  "./images/kesaravnaspolja2.jpg",
  "./images/brawnWhiteGroup.jpeg",
  "./images/kesaravnaspolja3.jpg",
  "./images/kesaupredena3.jpg",
  "./images/tvojaideja.jpeg",
];

const MainPage = () => {
  useEffect(() => {
    // Initialize slick carousel
    $(".gallery").slick({
      centerMode: true,
      slidesToShow: 5,
      autoplay: true,
      autoplaySpeed: 1500,
      speed: 1000,
      dots: false,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 980,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerMode: true,
            slidesToShow: 1,
          },
        },
      ],
    });

    // Cleanup on component unmount
    return () => {
      $(".gallery").slick("unslick");
    };
  }, []);
  return (
    <div className="wrapper">
      <Header page="POČETNA" />
      <div className="mainpart">
        <div className="part1">
          <div className="part1-container">
            <div className="row ">
              <Box textAlign={"center"} marginY={"150px"} width={"100%"}>
                <h3 className="">
                  {" "}
                  Štampa na papirnim kesama, papirne kese i proizvodnja
                  plastične ambalaže
                </h3>
                <h1 className="glow-text" id="main-text">
                  Vaša reklama je naša briga!!!
                </h1>
                <Link to="/onama" className=" btn button mt-5">
                  Detaljnije {">>>"}
                </Link>
                <Box></Box>
              </Box>
            </div>
          </div>
        </div>
        <div className="part2 pt-5">
          <div className="part2-container">
            <h1 className="text-center">Takođe u ponudi</h1>
            <div className="row">
              <RenderCard
                imgString={"./images/group1.jpg"}
                linkTo={"/papirnekesesaunutrasnjomruckom"}
                text={"Papirne kese"}
              />
              <RenderCard
                imgString={"./images/pvckese3.jpg"}
                linkTo={"/pvckese"}
                text={"Kese tregerice"}
              />
              <RenderCard
                imgString={"./images/butik1.jpg"}
                linkTo={"/butikkese"}
                text={"Butik kese"}
              />
              <RenderCard
                imgString={"./images/vrecesve.jpg"}
                linkTo={"/pvcvrece"}
                text={"PVC vreće"}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="gallery">
          {imageSources.map((src, index) => (
            <div key={index}>
              <img
                className="slider-img"
                src={src}
                alt={`Image ${index + 1}`}
              />
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MainPage;
