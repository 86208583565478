import React from "react";
import { Link } from "react-router-dom";

import Footer from "./Footer";
import Header from "../components/Header";

const PageNotFound = () => {
  return (
    <div className="wrapper">
      <Header />
      <div className="text-center mb-5 mt-5" style={{ height: "250px" }}>
        <h1 className="">Stranica nije pronađena</h1>
        <Link className="link-btn" to="/">
          Nazad na početnu
        </Link>
      </div>
      <Footer />
    </div> //end of wrapper
  );
};

export default PageNotFound;
