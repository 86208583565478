import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import MainPage from "./pages/MainPage";
import Onama from "./pages/Onama";
import SaStampom from "./pages/SaStampom";
import PVCkese from "./pages/PVCkese";
import PVCvrece from "./pages/PVCvrece";
import SaUnutrasnjom from "./pages/SaUnutrasnjom";
import SaUpredenom from "./pages/SaUpredenom";
import SpecifikacijaProizvoda from "./pages/SpecifikacijaProizvoda";
import Kontakt from "./pages/Kontakt";
import PageNotFound from "./pages/PageNotFound";
import { fetchPosts, submitOrder } from "./actions";
import ButikKese from "./pages/ButikKese";

export const AppContext = createContext({
  proizvodi: [],
  addToChart: (proizvod, kolicina, boja) => {},
  inChart: [],
  setInChart: (data) => {},
  handleOrder: (chart, contactValues) => {},
});

const App = () => {
  const [proizvodi, setProizvodi] = useState([]);
  const [inChart, setInChart] = useState([]);

  const handleOrder = (chart, contactValues) => {
    const body = {
      ...contactValues,
      chart,
    };

    return submitOrder(body)
      .then(() => {
        setInChart([]);
        toast(
          "Upit za narudžbinu je uspešno podnet. Kontaktiraćemo vaš u najkraćem roku. Hvala na poverenju.",
          { type: "success" }
        );
      })
      .catch((error) => {
        console.log(error);
        toast("Došlo je do greške...", { type: "error" });
      });
  };

  const addToChart = (proizvod, kolicina, boja) => {
    let index = -1;
    for (let i = 0; i < inChart.length; i++) {
      const element = inChart[i];
      if (element.proizvod.id === proizvod.id) {
        index = i;
      }
    }
    if (index !== -1 && inChart[index].boja === boja) {
      const newChart = [...inChart];
      newChart[index].kolicina = newChart[index].kolicina + kolicina;
      setInChart(newChart);
    } else {
      if (kolicina > 0) {
        setInChart([
          ...inChart,
          {
            proizvod,
            kolicina,
            boja,
          },
        ]);
      }
    }
  };

  useEffect(() => {
    fetchPosts().then((response) => setProizvodi(response));
  }, []);
  return (
    <div>
      <AppContext.Provider
        value={{ proizvodi, addToChart, inChart, setInChart, handleOrder }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MainPage />} />
            <Route path="/onama" element={<Onama />} />
            <Route path="/papirnekesesastampom" element={<SaStampom />} />
            <Route path="/pvckese" element={<PVCkese />} />
            <Route path="/pvcvrece" element={<PVCvrece />} />
            <Route path="/butikkese" element={<ButikKese />} />

            <Route
              path="/papirnekesesaunutrasnjomruckom"
              element={<SaUnutrasnjom />}
            />
            <Route
              path="/papirnekesesaupredenomruckom"
              element={<SaUpredenom />}
            />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route
              path="/specifikacijaproizvoda/:tag/:id"
              element={<SpecifikacijaProizvoda />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </AppContext.Provider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default App;
