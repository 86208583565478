import posts from "../apis/posts";
import { toast } from "react-toastify";

export const fetchPosts = async () => {
  const response = await posts.get("/data");
  return response.data;
};

export const createContact = async (formValues) => {
  const response = await posts
    .post("/contact", { formValues })
    .then(() => toast("Poruka je poslata", { type: "success" }))
    .catch((err) => {
      console.log(err);
      toast("Došlo je do greške...", { type: "error" });
    });

  return response;
};

export const submitOrder = async (body) => {
  const response = await posts
    .post("/submit-request", { ...body })
    .catch((err) => {
      console.log(err);
    });

  return response;
};
