import React from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import Chart from "./Chart";

const pages = [
  {
    name: "POČETNA",
    link: "/",
  },
  {
    name: "O NAMA",
    link: "/onama",
  },
  {
    name: "PAPIRNE KESE SA ŠTAMPOM",
    link: "/papirnekesesastampom",
  },
  {
    name: "PAPIRNE KESE",
    link: "/papirnekesesaunutrasnjomruckom",
  },
  {
    name: "KESE TREGERICE",
    link: "/pvckese",
  },
  {
    name: "BUTIK KESE",
    link: "/butikkese",
  },
  {
    name: "PVC VREĆE",
    link: "/pvcvrece",
  },
  {
    name: "KONTAKT",
    link: "/kontakt",
  },
];

class Header extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div style={{ width: "100%" }} id="navbar">
        <Logo />
        <div style={{ width: "100%" }} className="col-md text-right">
          <div style={{ width: "100%" }}>
            <nav
              style={{ width: "100%" }}
              id="navbar"
              className="navbar navbar-expand-xl navbar-light d-flex justify-content-end pt-3 pb-3 "
            >
              <button
                className="navbar-toggler ml-5"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse ml-5 "
                id="navbarSupportedContent"
                style={{ justifyContent: "center" }}
              >
                <ul className="navbar-nav ml-3" id="navbar">
                  {pages.map((page, index) => (
                    <div key={index}>
                      {page.subpages && page.subpages.length > 0 ? (
                        <li
                          key={page.name}
                          className="nav-item dropdown navItem"
                        >
                          <p
                            className="nav-link dropdown-toggle"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            {page.name}
                          </p>
                          <div
                            className="dropdown-menu p-0"
                            id="dropdown-item"
                            aria-labelledby="navbarDropdown"
                          >
                            {page.subpages.map((subpage) => (
                              <Link
                                key={subpage.name}
                                className={
                                  subpage.movedToRight
                                    ? "dropdown-item pl-5 "
                                    : "dropdown-item"
                                }
                                id={
                                  subpage.name === this.props.page
                                    ? "active"
                                    : ""
                                }
                                to={subpage.link}
                              >
                                {subpage.name}
                              </Link>
                            ))}
                          </div>
                        </li>
                      ) : (
                        <li key={page.name} className="nav-item navItem ">
                          <Link
                            className="nav-link"
                            id={page.name === this.props.page ? "active" : ""}
                            to={page.link}
                          >
                            {page.name}
                          </Link>
                        </li>
                      )}
                    </div>
                  ))}
                </ul>
              </div>
            </nav>
          </div>
          <Chart />
        </div>
      </div>
    );
  }
}

export default Header;
